import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@aqsi/ui/libs/material-ui/styles';
import { ReceiptContent, showError } from '@aqsi/ui';
import { isUUID } from '@aqsi/utils';

import loadReceipt from '../../api';


import TopBar from './components/TopBar';
import EmptyState from './components/EmptyState';


const useStyles = makeStyles((theme) => ({
  main: {
    height: '100vh',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 0,
    padding: theme.spacing(4),
  },
}));

export default function Receipt({ match: { params: { id = '' } }, isOrangeDataReceipt }) {
  const classes = useStyles();
  const [receipt, setReceipt] = useState({});
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!id || !isUUID(id)) {
      return;
    }
    const load = async () => {
      try {
        setLoading(true);
        setLoaded(false);
        const data = await loadReceipt(id);
        setReceipt(data);
        setLoading(false);
        setLoaded(true);
      } catch (error) {
        setLoaded(false);
        setLoading(false);
        showError(error.message);
      }
    };
    load();
  }, [id]);

  const showEmptyState = loaded && (Object.keys(receipt).length === 0 || (isOrangeDataReceipt && receipt?.fiscalizationStatus !== 3)); // eslint-disable-line max-len

  return (
    <>
      <TopBar />

      <main className={classes.main}>
        {(!id || !isUUID(id)) && <EmptyState text="Некорректная ссылка на чек" />}
        {showEmptyState
          ? <EmptyState isOrangeDataReceipt={isOrangeDataReceipt} />
          : <ReceiptContent receipt={receipt} loaded={loaded} loading={loading} showInfo={false} />}
      </main>
    </>
  );
}


Receipt.propTypes = {
  match: PropTypes.shape({ params: PropTypes.shape({ id: PropTypes.string }) }).isRequired,
  isOrangeDataReceipt: PropTypes.bool,
};

Receipt.defaultProps = { isOrangeDataReceipt: false };
